import React, { useEffect } from 'react'
import Header from "../components/Header"
// import Footer from "../components/Footer"
import NewFooter from '../components/NewFooter';
import { VscGitPullRequestCreate } from "react-icons/vsc";
import { astrattoLogo, ourTeam, aboutBannerbox_1_img, aboutBannerbox_2_img, aboutBannerboxImg, aboutbannerRight_1_img, aboutbannerRight_2_img,  visionInnovateIcon , visionInspireIcon} from '../assets';
import LoadingScreen from '../components/LoadingScreen';

const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <LoadingScreen />
            <Header />
            <div className='about-main-cover-wrapper'>
                <div className='container'>
                    <div className='about-header-title-container w-75 m-auto my-5'>
                        <div className="about-title">
                            <h1 className='text-center'>ABOUT US</h1>
                        </div>
                    </div>

                    <div className='about-banner-cover'>
                        <div className='about-banner-boxes-main-cover'>
                            <div className='row d-flex justify-content-center align-items-lg-end'>
                                <div className='col-lg-3 col-4'>
                                    <div className='about-banner-content-box-cover d-flex flex-column justify-content-end about-banner-first-view'>
                                        <div className='about-banner-img-box left-box1 w-75 mb-3'>
                                            <img src={aboutBannerbox_1_img}></img>
                                        </div>
                                        <div className='about-banner-img-box'>
                                            <img src={aboutBannerbox_2_img}></img>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-4'>
                                    <div className='about-banner-main-box'>
                                        <img src={aboutBannerboxImg}></img>
                                    </div>
                                </div>
                                <div className='col-lg-3 col-4'>
                                    <div className='about-banner-content-box-cover about-banner-second-view'>
                                        <div className='about-banner-img-box mb-3 about-right-banner-first'>
                                            <img src={aboutbannerRight_1_img}></img>
                                        </div>
                                        <div className='about-banner-img-box bannerRightBox2'>
                                            <img src={aboutbannerRight_2_img}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='about-header-title-container m-auto'>
                        <div className='about-content-box d-flex justify-content-center flex-column'>

                            {/* <div className='about-banner-cover d-flex justify-content-center'>
                                <img className='about-astrato-logo-box' src={aboutWingBanner}></img>
                            </div> */}
                            <div className="about-title">
                                <h1 className='text-center'>WHO WE ARE</h1>
                            </div>

                            <div className='row d-flex align-items-center mb-5'>
                                <div className='col-lg-6'>
                                    <div className='about-banner-type2-main-cover-wrapper mx-auto'>
                                        <img className='about-banner-type2-img-box' src={astrattoLogo} />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className="about-para-content-box">
                                        <p className='about-astratto-production-para'>
                                            Welcome to Astratto, a premier production house located in the vibrant city of Bangalore. With 7 years of experience in the industry, we specialize in delivering high-quality visual content that tells compelling stories and captures the essence of our clients' visions.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="about-title mt-5 mb-5">
                                <h1 className='text-center'>Our Team</h1>
                            </div>
                            <div className='row d-flex align-items-center mb-5'>
                                <div className='col-lg-6 order-lg-0 order-1'>
                                    <div className="about-para-content-box">
                                        <h5>At Astratto, we take pride in our talented and dedicated team, which includes:</h5>
                                        <p className=''> 
                                            <b>Cinematographers:</b> Skilled professionals who bring creativity and technical expertise to every project.
                                            <br/>
                                            <b>Drone Operators:</b> Certified experts who provide breathtaking aerial shots, enhancing the visual storytelling experience.
                                            <br/>
                                            <b>Producers:</b> Well-experienced individuals who ensure that every aspect of production runs smoothly and efficiently.
                                            <br/>
                                            <b>Creative Team Members:
                                                </b> A passionate group of writers, editors, and designers who collaborate to deliver stunning results.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-lg-6 order-lg-1 order-0'>
                                    <div className='about-banner-type2-main-cover-wrapper mx-auto our-team-wrapper-box-cover'>
                                        <img className='about-banner-type2-img-box' src={ourTeam} />

                                    </div>
                                </div>

                            </div>
                            
                            <div className="about-title mt-5 mb-3">
                                <h1 className='text-center'>Our Vision</h1>
                                {/* <h5>At Astratto, our vision is to:</h5> */}
                            </div>
                            <div className='row d-flex align-items-center mb-5'>
                                <div className='col-lg-4'>
                                    <div className="vision-card-wrapper">
                                        <div className='vision-card'>
                                            <div className="vision-card-header-cover">
                                                <h5 className='vision-card-title'>Create</h5>
                                                <div className="vision-card-icon">
                                                    <VscGitPullRequestCreate />
                                                </div>
                                            </div>
                                            <h5>Transform ideas into visual masterpieces.
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className="vision-card-wrapper">
                                        <div className='vision-card'>
                                            <div className="vision-card-header-cover">
                                                <h5 className='vision-card-title'>Inspire</h5>
                                                <div className="vision-card-icon">
                                                    <img src={visionInspireIcon}></img>
                                                </div>
                                            </div>
                                            <h5>Engage audiences through compelling narratives.
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className="vision-card-wrapper">
                                        <div className='vision-card'>
                                            <div className="vision-card-header-cover">
                                                <h5 className='vision-card-title'>Innovate:</h5>
                                                <div className="vision-card-icon">
                                                    <img src={visionInnovateIcon}></img>
                                                </div>
                                            </div>
                                            <h5> Utilize the latest technology and techniques in filmmaking.
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='company-metrices-cover-wrapper'>
                    <div className='container'>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-lg-3 col-6 mb-lg-1 mb-3 d-flex justify-content-center'>
                                <div className='company-metric-card-box-cover'>
                                    <div className='company-metric-card-box'>
                                        <h5>Videos</h5>
                                        <h2>300+</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-6 mb-lg-1 mb-3 d-flex justify-content-center'>
                                <div className='company-metric-card-box-cover'>
                                    <div className='company-metric-card-box'>
                                        <h5>Clients</h5>
                                        <h2>150+</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-6 mb-lg-1 mb-3 d-flex justify-content-center'>
                                <div className='company-metric-card-box-cover'>
                                    <div className='company-metric-card-box'>
                                        <h5>Projects</h5>
                                        <h2>200+</h2>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3 col-6 mb-lg-1 mb-3 d-flex justify-content-center'>
                                <div className='company-metric-card-box-cover'>
                                    <div className='company-metric-card-box'>
                                        <h5>Recordings(Hrs)</h5>
                                        <h2>600+</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NewFooter />
        </>
    )
}

export default About
