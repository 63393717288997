import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { IoLogoInstagram } from "react-icons/io5";
import { ImFacebook2 } from "react-icons/im";
import { IoLogoYoutube } from "react-icons/io";
import { BsWhatsapp } from "react-icons/bs";
import { astrattoLogo } from '../assets';
import { WHATSAPP_NUMBER } from '../Constants';


const NewFooter = () => {

    const handleClick = () => {
        const url = `https://api.whatsapp.com/send?phone=${WHATSAPP_NUMBER}&text=${encodeURIComponent("Hello ")}`;
        window.open(url, '_blank');
    };
    return (
        <div className='footer-main-cover-wrapper'>
            <div className='powered-main-wrapper'>
                <p>© 2024 Astratto. All rights reserved.</p>

                <div className='social-box-cover-wrapper'>
                    <Link to="https://www.instagram.com/astratto_stories?igsh=azJ6d3poZ2gyZzZo" target='_blank'>
                        <IoLogoInstagram/>
                    </Link>
                    <span onClick={handleClick}>
                        <BsWhatsapp/>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default NewFooter
