import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { IoLogoInstagram } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";
import { astrattoLogo } from '../assets';
import { WHATSAPP_NUMBER } from '../Constants';

const Header = ({ color }) => {


  const handleClick = () => {
    const url = `https://api.whatsapp.com/send?phone=${WHATSAPP_NUMBER}&text=${encodeURIComponent("Hello ")}`;
    window.open(url, '_blank');
  };

  return (
    <div className='header-navbar-main-wrapper' >
      <Navbar expand="lg" className="header">
        <Container>
          <Navbar.Brand href="#home">
            <img src={astrattoLogo} alt="Main Logo" className="main-logo"></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav header-navlink-cover">
            <Nav className="header-navlinks">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/contact">Contact</Link>
            </Nav>
          </Navbar.Collapse>

          <div className='social-card-box'>
            <ul>
              <li>
                <Link to="https://www.instagram.com/astratto_stories?igsh=azJ6d3poZ2gyZzZo">
                  <IoLogoInstagram />
                </Link>
              </li>
              <li>
                <Link onClick={handleClick}>
                  <BsWhatsapp />
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;