import VideoPlayer from './HeroVideoPlayer'
import { heroBannerVid } from '../assets'
import "swiper/swiper-bundle.css";
import "swiper/css";


const HeroNewSection = () => {

    return (
        <>
            <div className='Hero-section-main-revamp-wrapper'>
                <video src={heroBannerVid} autoPlay loop muted playsInline />
            </div>
        </>
    )
}

export default HeroNewSection
